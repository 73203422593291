
import Vue from 'vue'
import MainNavigationDrawer from "@/components/Drawers/MainNavigationDrawer.vue";
import ItemsSliderWithTitle from "@/components/Items/ItemsSliderWithTitle.vue"
import { mapActions, mapState } from 'vuex';
import StoreService from '@/services/storeService';
import StoreSettings from '@/models/StoreSettings';
import { EStoreType } from '@/enums/EStoreType';
import routerManager from '@/routerManagerNew/routerManager';
import Catalog from '../Catalog.vue';
import ElementGenerator from '@/components/UI/Elements/ElementGenerator.vue';
import OrderTypeSelector from '@/components/Orders/OrderTypeSelector.vue'
import { ECategorizationLayerGroup } from '@/models/StoreCategorizationsAndLayersStructure';

export default Vue.extend({
    props: {
        storeId: { 
            required: true   
        }
    },

    components: {
        "main-navigation-drawer": MainNavigationDrawer,
        "item-slider-with-title": ItemsSliderWithTitle,
        "catalog": Catalog,
        "element-generator": ElementGenerator,
        "order-type-selector": OrderTypeSelector
    },

    metaInfo: function() {
        return {
            title:  `${(this as any).partnerSettings.partnerName} | Home`,
        }
    },
    

    data:()=>({        
        dialog: false as boolean,
        currentCatalog: {images: []} as any
    }),

    computed: {        
        ...mapState("partner", ["appDataContent", "partnerSettings"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("product", ["storeHomePage", "categoriesAndSubCatList", "departmentsList"]),
        ...mapState("loading", ["storeHomePageLoading"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        listOfSectionsTitle(): string[] {
            // 1- Featured Items
            // 2- Promo Items
            // 3- recently added products
            // 4- shop by brand
            // 5- bundles
            return [    
                this.appDataContent.productTypeDescriptionByFeatureProducts, 
                this.appDataContent.productTypeDescriptionByPromo,
                this.appDataContent.productTypeDescriptionByNewlyAdded, 
                this.appDataContent.bundles, 
            ]
        },

        isStoreTypeConvinient(): boolean {
            return (this.selectedStoreDetails as StoreSettings).storeType == EStoreType.Convenient;
        }, 


        isDepartmentsHighestLayer(): boolean {
            return ((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.group == ECategorizationLayerGroup.GroupThree 
                || ((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.group == ECategorizationLayerGroup.GroupFour   
        }
    },

    methods: {
        onViewAllClick(index: number) {
            routerManager.goToItemsBySectionPage(this.listOfSectionsTitle[index], index);
        },

        onCustomCollectionViewAllClick(campaign: any) {
            this.$router.push({
                name: "productsPageByCampaign",
                params: {
                    campaignId: typeof campaign.itemsCollectionId == "undefined" ? campaign.itemsCollectionType : campaign.itemsCollectionId,
                    campaignName: campaign.campaignInfo.name,
                    campaignCollectionType: campaign.itemsCollectionType,
                    title: campaign.campaignInfo.name,
                },
                
                query: {
                    type: campaign.itemsCollectionType,
                    typeId: campaign.itemsCollectionId,
                }

            });
        },

        async getStoreHomePageCall() {   
            await StoreService.getStoreHomePageData();
        },

        setCurrentCatalog(catalog: any) {
            this.currentCatalog = catalog;
            this.dialog = true;
        }
    },

    watch: {
        async storeId() {
            await this.getStoreHomePageCall()
        },

        dialog() {
            this.$forceUpdate()
        },

        async isMobileViewDisplayed() {
            await this.getStoreHomePageCall();
        },

        storeHomePageLoading(val: boolean) {
            this.$emit("loadingState", val)
        } 
    },

    async mounted() {
        // OrderTypeService.SetDefaultOrderType();
        await this.getStoreHomePageCall();
    }
})
