import { render, staticRenderFns } from "./StoreCard.vue?vue&type=template&id=d85c5d50&scoped=true&"
import script from "./StoreCard.vue?vue&type=script&lang=ts&"
export * from "./StoreCard.vue?vue&type=script&lang=ts&"
import style0 from "./StoreCard.vue?vue&type=style&index=0&id=d85c5d50&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d85c5d50",
  null
  
)

export default component.exports