// External libraries
import Vue from 'vue'
import Vuex, { Module } from 'vuex'

// Models
import { FooterStructure, FooterPage } from '@/models/FooterStructure'
import { RootState } from '..'

Vue.use(Vuex)

export interface FooterState extends RootState {
    selectedPage: FooterPage
    footerStructure: Array<FooterStructure>
}

export default <Module<FooterState, RootState>>{
    namespaced: true,
    state: {
        selectedPage: {} as FooterPage,
        footerStructure: [] as Array<FooterStructure> // Initially an empty array, expecting to hold FooterStructure objects
    },
    getters: {
        getSelectedPage(state) {
            return state.selectedPage
        },
        getFooterStructure(state) {
            return state.footerStructure
        }
    },
    mutations: {
        setSelectedPage(state, page) {
            state.selectedPage = page
        },
        setFooterStructure(state, structure) {
            state.footerStructure = structure
        }
    },
    actions: {
        updateSelectedPage({ commit }, page: FooterPage) {
            commit('setSelectedPage', page)
        },
        updateFooterStructure({ commit }, structure: FooterStructure) {
            commit('setFooterStructure', structure)
        }
    }
}