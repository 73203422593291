
import Vue from 'vue'
import { mapState } from 'vuex';
import UITitle from '../Text/UITitle.vue';
import BrandCard from '@/components/Brand/BrandCard.vue';

export default Vue.extend({
    
    components: { 
        "ui-title": UITitle,
        "brand-card": BrandCard 
    },
  
    props: {
        items: {
            required: true
        },

        itemType: {
            required: true,
            type: Number,
            default: 1        
        },

        title: {
            required: false,
            type: String
        },

        isShowAllButtonEnabled: {
            required: false,
            default: false,
            type: Boolean
        },

        loading: {
            required: false,
            default: false,
            type: Boolean
        },

        elementWidth: {
            required: false,
            type: String,
            default: '0'
        }
    },

    data: () => ({
      randomNumber: Math.floor(Math.random() * 50000000) as number,
    }),

    computed: {
        ...mapState("ui", ["isMobileViewDisplayed"])
    },


    methods: {

        slideNext() {
            let next = document.getElementById(`${this.randomNumber}-next`);
            next != null ? next.click() : null;
        },

        slidePrev() {
            let prev = document.getElementById(`${this.randomNumber}-prev`);
            prev?.click();
        },

    },

    mounted() {
        this.$forceUpdate();
    }
})
