
// External libraries
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

// Api
import _requestHelper from '@/apiManager/_requestHelper'

// Models
import { FooterStructure } from '@/models/FooterStructure'

export default Vue.extend({
    data() {
        return {
            groupsLength: 1,
        }
    },
    computed: {
        ...mapState('footerModule', ['selectedPage', 'footerStructure']),
        ...mapState('partner', ['partnerSettings', 'appDataContent']),
        ...mapState('ui', ['isMobileViewDisplayed']),
        ...mapState('store', ['selectedStoreDetails']),

        whatsappPhoneNumber(): string | null {
            if (this.selectedStoreDetails.id > 0 && this.selectedStoreDetails.whatsAppNumbers.length > 0) {
                return this.selectedStoreDetails.whatsAppNumbers[0]
            }
            else if (this.partnerSettings.customerServiceWhatsAppNumber.length > 0) {
                return this.partnerSettings.customerServiceWhatsAppNumber
            }
            else {
                return null
            }
        },

        footerJsonConfig(): any {
            return this.partnerSettings && this.partnerSettings.wsaFooterConfigJson ? JSON.parse(this.partnerSettings.wsaFooterConfigJson) : {}
        }
    },
    methods: {
        ...mapActions('footerModule', ['updateSelectedPage', 'updateFooterStructure']),

        calculateWidth(length: number) {
            // Default to 1 to avoid division by zero
            return this.footerJsonConfig && this.footerJsonConfig.rightSectionHtml && this.footerJsonConfig.rightSectionHtml != '' ? `calc(52% / ${length || 1})` : `calc(65% / ${length || 1})`
        },

        // Helper method
        transformToSlug(input: string): string {
            if (!input) return '' // Handle empty or null input
            return input.toLowerCase().replace(/\s+/g, '-')
        },

        setPage(selectedPage: any, groupName: string) {
            if (selectedPage) {
                // Hyperlink
                if (selectedPage.isHyperLink) {
                    const link = document.createElement('a')
                    link.href = selectedPage.hyperLink
                    link.target = '_blank'
                    link.rel = 'noopener noreferrer'
                    link.click() // Programmatically click the link
                }
                // Page
                else {
                    this.$router.push({
                        name: 'DynamicFooterPage',
                        params: {
                            footerGroupName: groupName,
                            footerPageName: selectedPage.name
                        }
                    })
                    this.updateSelectedPage(selectedPage)
                }
            }
        },
    },
    mounted() {
        _requestHelper({
            url: 'wsa-footer/structure',
            method: 'GET',
            loaderKey: '',
            successCallback: async (result: Array<FooterStructure>) => {
                this.updateFooterStructure(result)
                this.groupsLength = result.length
                this.$forceUpdate()
            },
        })

        // Injecting it anyway in case the user switch from desktop to mobile (minized the window)
        // In case of mobile view, we are using v-list, and in order to alter the color and have it be the same as the one in the PS, we need to inject it via script like so
        const footerJsonConfig = this.footerJsonConfig
        const dynamicStyle = `
        .wsa-footer-mobile-list .v-icon {
            ${footerJsonConfig && footerJsonConfig.textColor ? `color: ${footerJsonConfig.textColor}` : `color: white`}
        }
        .wsa-footer-mobile-list .v-list-item__icon {
            ${footerJsonConfig && footerJsonConfig.textColor ? `color: ${footerJsonConfig.textColor}` : `color: white`}
        }
        .wsa-footer-mobile-list .v-list-item__title,
        .wsa-footer-mobile-list .v-list-group__items .v-list-item__content p {
            ${footerJsonConfig && footerJsonConfig.textColor ? `color: ${footerJsonConfig.textColor}` : `color: white`}
        }
        `

        const styleTag = document.createElement("style")
        styleTag.type = "text/css"
        styleTag.appendChild(document.createTextNode(dynamicStyle))
        document.head.appendChild(styleTag)
    },
})
