<template>
    <v-card   style="width:100%; z-index:1; border-radius:20px!important" flat  >  

     <GmapMap
           ref="googleMap"
           :center="centerToUse"
           :zoom="zoom"
           map-type-id="roadmap"
           style="width: 100%;"
           :style="`height: 250px`"
           :options="{
               zoomControl: false,
               mapTypeControl: false,
               scaleControl: false,
               streetViewControl: false,
               rotateControl: false,
               fullscreenControl: false,
               disableDefaultUi: true,
               isFractionalZoomEnabled: true,
               gestureHandling: 'greedy',
               styles: mapStyle
           }"
       >
       
           <template v-slot:visible v-if="isMapInitialized == true" >
              
              <!-- User Marker -->
              <GmapMarker                
                :icon="userMarker"                 
                :position="userPosition"
                :clickable="false"
                :draggable="false"                 
              />
              
              <!-- Driver Marker -->
              <GmapMarker      
                v-if="centerToUse.lat > 0 && centerToUse.lng > 0 && showDefaultMap == false"          
                :icon="driverMarker"                 
                :position="{ lat: parseFloat(centerToUse.lat), lng: parseFloat(centerToUse.lng) }"
                :key="centerToUse.lat"
                :clickable="false"
                :draggable="false"                 
              />

              <!-- Store Marker -->
              <GmapMarker                
                :icon="storeMarker"                 
                :position="{ lat: parseFloat(storeMarkerObject.lat), lng: parseFloat(storeMarkerObject.lng) }"
                :clickable="false"
                :draggable="false"                 
              />

              <!-- map polyline -->
              <gmap-polyline 
                v-if="polyline" 
                :path="polyline" 
                :strokeWeight="3" 
              />
           </template>
       </GmapMap>


    </v-card>
</template>

<script lang="js">
import Vue from 'vue'
import GmapVue, { getGoogleMapsAPI } from 'gmap-vue';
import  CustomGoogleAutocomplete  from 'vue-custom-google-autocomplete'
import {  mapGetters, mapState } from "vuex";
import GooglePlacesAutocomplete from "@/components/Location/GooglePlacesAutocomplete.vue"
import GoogleMapStyle from "./GoogleMapStyle"

Vue.use(CustomGoogleAutocomplete)
Vue.use(GmapVue, {
 load: {
   // [REQUIRED] This is the unique required value by Google Maps API
   key: 'AIzaSyC38-kEWYa8fFbgAlTS6wL2zF2hz-kumK0',
   // [OPTIONAL] This is required if you use the Autocomplete plugin
   // OR: libraries: 'places,drawing'
   // OR: libraries: 'places,drawing,visualization'
   // (as you require)
   libraries: 'places',

   // [OPTIONAL] If you want to set the version, you can do so:
   // v: '3.26',

   // This option was added on v3.0.0 but will be removed in the next major release.
   // If you already have an script tag that loads Google Maps API and you want to use it set you callback
   // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
   // to the `window` object, is the only requirement.
   customCallback: 'MyCustomCallback',
 },

 // [OPTIONAL] If you intend to programmatically custom event listener code
 // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
 // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
 // you might need to turn this on.
 autoBindAllEvents: false,

 // [OPTIONAL] If you want to manually install components, e.g.
 // import {GmapMarker} from 'gmap-vue/src/components/marker'
 // Vue.component('GmapMarker', GmapMarker)
 // then set installComponents to 'false'.
 // If you want to automatically install all the components this property must be set to 'true':
 installComponents: true,

 // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
 dynamicLoad: false,
})


export default Vue.extend({
   components:{ "auto-complete-location-search": GooglePlacesAutocomplete},
   
   props: ["center", "polyline", "storeMarkerObject", "userPosition", "showDefaultMap"],

   data: () => ({
       zoom: 16,
       isMapInitialized: false,
       mapStyle: GoogleMapStyle,
   }),

   computed: {

      ...mapState("partner", ["partnerSettings"]),
      ...mapState("store", ["selectedStoreDetails"]),
       
       google: getGoogleMapsAPI,

       userMarker() {
         var image = {
           url: this.partnerSettings.userMapMarkerImageUrl,
           size: new google.maps.Size(71, 71),
           origin: new google.maps.Point(0, 0),
           anchor: new google.maps.Point(17, 34),
           scaledSize: new google.maps.Size(35, 35)
         };

         return image
       },
       
       driverMarker() {
         var image = {
           url: this.partnerSettings.driverMapMarkerURL,
           size: new google.maps.Size(71, 71),
           origin: new google.maps.Point(0, 0),
           anchor: new google.maps.Point(17, 34),
           scaledSize: new google.maps.Size(35, 35)
         };

         return image
       },

       storeMarker() {
         var image = {
           url: this.storeMarkerObject.marker,
           size: new google.maps.Size(71, 71),
           origin: new google.maps.Point(0, 0),
           anchor: new google.maps.Point(17, 34),
           scaledSize: new google.maps.Size(35, 35)
         };

         return image
       },

       centerToUse: {
           get(){
               return this.center;
           },

           set(value) {
               this.$emit("changed", value);
           }
       },
   },

   watch: {
    centerToUse() {
      if(this.showDefaultMap == false) {
        this.$forceUpdate();
      }
    }
   },
   
   model: {
       prop: "center",
       event: "changed",
   },

  async mounted() {
      await this.$gmapApiPromiseLazy().then(() => {
        this.isMapInitialized = !!window.google;
    });
  }
  
})
</script>