import Vue from "vue"
import Vuex, { StoreOptions, Plugin } from "vuex"
import { modules, ui, auth } from "./modules"
import VuexPersistence from "vuex-persist"
import cusService from "./modules/customerServiceModule"
import maps from "./modules/mapModule"
import order from "./modules/orderModule"
import schedule from "./modules/scheduleModule"
import loading from "./modules/loadingModule"
import partner from "./modules/partnerModule"
import stores from "./modules/storeModule"
import cart from "./modules/cartModule"
import product from "./modules/productModule"
import addressModule from "./modules/addressModule"
import firebaseModule from "./modules/firebaseModule"
import eWallet from "./modules/eWalletModule"
import loyalty from "./modules/loyaltyModule"
import footerModule from "./modules/dynamicFooter"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "suppy-wsa",
  modules: ["auth", "cart"],
});

Vue.use(Vuex);

modules["auth"] = auth;
modules["ui"] = ui;
modules["cusService"] = cusService;
modules["maps"] = maps;
modules["order"] = order;
modules["schedule"] = schedule;
modules["schedule"] = schedule;
modules["loading"] = loading;
modules["partner"] = partner;
modules["store"] = stores;
modules["cart"] = cart;
modules["product"] = product;
modules["address"] = addressModule;
modules["firebase"] = firebaseModule;
modules["eWallet"] = eWallet;
modules["loyalty"] = loyalty;
modules["footerModule"] = footerModule;

export interface RootState {
}

const store: StoreOptions<RootState> = {
  modules,
  getters: {
    globalLoading: (state: RootState) =>
      Object.values(state)
        .map((m) => m.loading)
        .reduce((l, i) => l || i),
  },
  plugins: [vuexLocal.plugin] as Plugin<RootState>[],
};

export default new Vuex.Store(store);
