
import Vue from 'vue'
import UITitle from "@/components/UI/Text/UITitle.vue"
import { mapActions, mapState } from 'vuex';
import { ETypeOfRequest } from '@/enums/ETypeOfRequest'
import StoreSettings from '@/models/StoreSettings';
import ItemsGroups from '@/components/Items/ItemsTypes.vue'
import ItemsList from '@/components/Items/ItemsList.vue'
import EmptyCart from '@/components/Cart/EmptyCart.vue'
import ItemsSliderWithTitle from "@/components/Items/ItemsSliderWithTitle.vue"

import { EProductsPageType, RouteMetaInterface } from '@/routerManagerNew/routes'
import Product from '@/models/Product';
import ProductMappingBySubcategoryAndType from '@/models/ProductMappingBySubcategoryAndType';
import { Route } from 'vue-router';
import Breadcrumbds from '@/components/UI/Breadcrumbs.vue';


enum ECampaignCollectionType  {
    None = 0,
    CustomCollection = 1,
    RecentlyAddedItems = 2,
    PromotedItems = 3,
    FeaturedItems = 4,
    BoostedItems = 5
}

export default Vue.extend({
    name: "products-page",

    props: {
        categoryId: {
            required: false,            
        },

        subcategoryId: {
            required: false,            
        },

        categoryName: {
            required: false,
            type: String
        },


        subcategoryName: {
            required: false,
            type: String
        },

        campaignName: {
            required: false,
            type: String
        },

        campaignCollectionId: {
            required: false,            
        },

        itemsCollectionType: {
            required: false,
            default: 0       
        },

        sectionId: {
            required: false,            
        },
        
        brandId: {
            required: false,            
        },

        brandName: {
            required: false,            
        },

        isFromMobileSearchComponent: {
            required: false,
            default: false
        },

        searchQueryFromMobileComponent: {
            required: false,            
            default: ""
        },

        hideBreadcrumbs: {
            required: false,            
            default: false
        },

        itemsCollectionId: {
            required: false
        }
    },

    components: {
        "ui-title": UITitle,
        "items-groups": ItemsGroups, 
        "items-list": ItemsList, 
        "empty-cart": EmptyCart,
        "item-slider-with-title": ItemsSliderWithTitle,
        "breadcrumbds": Breadcrumbds,
    },

    data: () => ({
        listOfItemsToBeDisplayed: [] as any,
        itemGroupSelected: 0 as number,
        loading: false as boolean,
        title: "" as string,     
        searchDebounce: 0 as number   
    }),


    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("product", ["listOfItems", "listOfSearchedItems"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        route(): Route {
            return this.$route;
        },

        pageType(): EProductsPageType | undefined {
            return (this.route.meta as RouteMetaInterface).productsPageType;
        },

        isPageSearch(): boolean {
            return this.pageType == EProductsPageType.bySearch || this.isFromMobileSearchComponent == true
        },

        itemsToManipulate(): any[] {
            if(this.isPageSearch == true) 
                return (this as any).listOfSearchedItems;

            else 
                return (this as any).listOfItems;
        },

        searchQuery(): string {
            if(this.isFromMobileSearchComponent == true)
                return this.searchQueryFromMobileComponent as string;
            return  this.route.query["q"] as string
        },


        /** check if breadcrumb is displayed */
        isBreadcrumbsItemsDisplayed(): boolean {
            return this.$route.name != "storeHomePage"
        },


        /** check if the empty items page is displayed or not */
        isEmptyPageDisplayed(): boolean {

            // if the search component is displayed on the mobile
            if(this.isFromMobileSearchComponent == true) {
                // if the search query is less then 2 chars, don't display the empty page
                if(this.searchQuery.length < 3)
                    return false;

                // if the search query is more then 2 chars, and the request is loaded and the list of items is empty
                // show the empty page
                else if(this.loading == false && this.listOfItemsToBeDisplayed.length == 0) {
                    return true
                }

                return false;
            }

            else {
                return this.loading == false && this.listOfItemsToBeDisplayed.length == 0;
            }
        },

        /** check if the filter section is displayed or not */
        isFilterSectionDisplayed(): boolean {
            return this.loading == true || this.listOfItemsToBeDisplayed.length > 0;
        }


    },

    methods: {    
        ...mapActions("cusService", ["getInAppMsg"]),
        ...mapActions("product", ["getItems", "searchItems"]),

        filterItems() {

            if(this.isPageSearch == true) {                
                if(this.itemGroupSelected == 0) {            
                    this.listOfItemsToBeDisplayed = (this.itemsToManipulate as Product[]);
                }

                else {
                    this.listOfItemsToBeDisplayed = (this.itemsToManipulate as Product[])
                        .filter((item: any) => item.itemTypeId == this.itemGroupSelected);
                }
            }

            else {
                // get all items
                if( this.itemGroupSelected == 0 ) {
                    this.listOfItemsToBeDisplayed = (this.itemsToManipulate as ProductMappingBySubcategoryAndType[]);
                }

                else {
                    // get items by group
                    if(this.pageType != EProductsPageType.bySearch) {
                        this.listOfItemsToBeDisplayed = (this.itemsToManipulate as ProductMappingBySubcategoryAndType[])
                            .filter((item: ProductMappingBySubcategoryAndType) => item.groupId == this.itemGroupSelected);
                    }                
                }

                if(this.listOfItemsToBeDisplayed.length == 0)
                    this.listOfItemsToBeDisplayed = (this.itemsToManipulate as ProductMappingBySubcategoryAndType[]);
            }
        },


        /**
         * Get list of products
         */
        async getProducts() {
            this.$emit("loadingState", true);
            this.loading = true;
            this.itemGroupSelected = 0;
          
            var typeOfRequest = ETypeOfRequest.BySubCat;

            // update the type of request depending on the type
            if(this.pageType != null && typeof this.pageType != "undefined") {

                if(this.pageType == EProductsPageType.byCategory) {
                    this.title = this.$route.params["categoryName"];
                    
                    if(typeof (this as any).$route.query["type"] == "undefined") {
                        typeOfRequest = ETypeOfRequest.ByCategory
                    }

                    else if((this as any).$route.query["type"] == 0)
                        typeOfRequest = ETypeOfRequest.ByCategory;

                    else {
                        typeOfRequest = ETypeOfRequest.ByCustomCollectionOfItems;
                    }
                }

                else if(this.pageType == EProductsPageType.bySubcategory) {
                    typeOfRequest = ETypeOfRequest.BySubCat;
                    this.title = this.$route.params["subcategoryName"];
                }

                else if(this.pageType == EProductsPageType.byCollection) {
                    typeOfRequest = ETypeOfRequest.ByCustomCollectionOfItems;
                    this.title = this.campaignName;
                }            

                else if(this.pageType == EProductsPageType.bySection) {      
                    this.title = this.$route.params["section"];

                    // set the request type depedning on the section id
                    switch(parseInt(this.sectionId as any)) {
                        case 0: typeOfRequest = ETypeOfRequest.ByFeaturedItems;                            
                            break;

                        case 1: typeOfRequest = ETypeOfRequest.ByPromo;
                            break;

                        case 2: typeOfRequest = ETypeOfRequest.ByNewlyAdded;
                            break;
                        
                        case 3: typeOfRequest = ETypeOfRequest.ByBundles;
                            break;

                        default: typeOfRequest = ETypeOfRequest.ByFeaturedItems;
                            break;
                    }
                }

                else if(this.pageType == EProductsPageType.byBrand) {
                    typeOfRequest = ETypeOfRequest.ByBrand
                }
            }

            if(this.isPageSearch) {
                await this.searchItems({
                    branchId: (this as any).selectedStoreDetails.id,
                    light: false,
                    includeProbablyOOS: true,
                    sortBy: 0,
                    typeOfRequest: 1,
                    search: this.searchQuery,
                    isASearchRequest: true
                })

                if(this.itemsToManipulate.length > 0) {
                    var str = (this as any).appDataContent.searchResultText.replace('{1}', this.itemsToManipulate.length);
                    var str = str.replace('{2}', this.searchQuery);
                    this.title = str;
                }

                else {
                    this.title = this.searchQuery;
                }
                
            }

            else {
                await this.getItems({
                    branchId: ((this as any).selectedStoreDetails as StoreSettings).id,
                    search: "",
                    light: false,
                    includeProbablyOOS: true,
                    sortBy: 0,
                    typeOfRequest: typeOfRequest,
                    subCategoryId: this.subcategoryId,
                    categoryId: this.categoryId,
                    requestedFrom: 1,
                    itemsCollectionId:
                        this.itemsCollectionId != null && typeof this.itemsCollectionId != 'undefined' && (this.itemsCollectionId as number) > 0
                            ? this.itemsCollectionId
                            : this.$route.query["typeId"],
                    itemsCollectionType: 
                        this.itemsCollectionType != null && typeof this.itemsCollectionType != 'undefined' && (this.itemsCollectionType as number) > 0
                            ? this.itemsCollectionType
                            : this.$route.query["type"],
                    brandId: this.brandId, 
                });
                
            }

            this.$emit("loadingState",false) ;
            this.filterItems();
            this.loading = false;
        }        
    },

    async mounted() { 
        if(this.isFromMobileSearchComponent == false)
            await this.getProducts();
    },

    watch: {
        itemGroupSelected() {
            this.filterItems();
        },

        async subcategoryId() {            
            await this.getProducts();
        },

        async categoryId() {
            await this.getProducts();
        },

        async searchQuery(newVal) {
            if(this.isFromMobileSearchComponent == true) {

                // fake loader
                if(newVal.length == 0) {
                    this.loading = false;
                    this.$emit("loadingState", false);
                }
                else {
                    this.$emit("loadingState", true);
                    this.loading = true;
                }
                

                if((newVal as string).length > 1) {
                    await  this.getProducts();
                }
            }

            else {
                await this.getProducts();
            }
        },
    }
})
