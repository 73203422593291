import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import Category from "@/models/Category";
import Product from "@/models/Product";
import Subcategory from "@/models/Subcategory";
import ProductMappingBySubcategoryAndType from "@/models/ProductMappingBySubcategoryAndType";
import { EItemFlavor } from "@/enums/EItemFlavor";
import Department from "@/models/Department";
import { StoreState } from "./storeModule";
import { BranchCategorizationsAndLayersStructureService } from "@/models/StoreCategorizationsAndLayersStructure";

export interface ProductState {
    departmentsList: Array<Department>;
    categoriesAndSubCatList: Array<Category>;
    listOfItemsByCatSubcat: Map<string, Product[]>;
    listOfItems: ProductMappingBySubcategoryAndType[];
    listOfSearchedItems: Product[];
    listOfItemsToBeDisplayed: any;
    lastSelectedCategory: Category;
    lastSelectedSubcategory: Subcategory;
    isFirstItemsRequest: boolean;
    similarItemsByType: any[];
    similarItemsByBrand: any[];
    similarItemsByBougthWith: any[];
    storeHomePage: any;
    currentSelectedItem: Product;
    itemDetailsDialogValue: boolean;
    isProductDetailsLoading: boolean;
}

export default <Module<ProductState, RootState>> {
    namespaced: true,

    state: {
        departmentsList: [] as Department[],
        categoriesAndSubCatList: [] as Category[],
        listOfItemsByCatSubcat: new Map() as Map<string, Product[]>,
        listOfSearchedItems: [] as Product[],
        lastSelectedCategory: {} as Category,
        lastSelectedSubcategory: {} as Subcategory,
        isFirstItemsRequest: true,
        listOfItems: [],
        listOfItemsToBeDisplayed: [],
        similarItemsByType: [],
        similarItemsByBrand: [],
        similarItemsByBougthWith: [],
        storeHomePage: {
            products: {
                featuredItems: [] as Product[],
                promoItems: [] as Product[],
                recentlyAddedItems: [] as Product[],
                bundlesList: [] as Product[]
            },

            brandsList: [] as any,

            campaignBanners: {
                customBanners: [] as any,
            },

            customCollections: [] as any            
        },
        currentSelectedItem: {} as Product,
        itemDetailsDialogValue: false as boolean,
        isProductDetailsLoading: false as boolean,
        
    },

    mutations: {
        UPDATE_PRODUCT_DETAILS_LOADING_STATE(state, isProductDetailsLoading: boolean) {
            state.isProductDetailsLoading = isProductDetailsLoading;
        },

        FETCH_LIST_OF_CATS_AND_SUBCATS(state, payload: any) {
            state.categoriesAndSubCatList = [] as Category[];
            var index = 9000;
            var listOfCategoriesAndSubCat: any = payload.listOfCategoriesAndSubCat;
            
            var categorization: BranchCategorizationsAndLayersStructureService = payload.storeCategorization;

            // add custom category if any
            // don't add if the category is the highest layer and the categroies and subcats are grouped
            if(!categorization.isCategoriesAndSubcategoriesGrouped) {
                if(typeof listOfCategoriesAndSubCat.campaignsAsCategories != 'undefined' && listOfCategoriesAndSubCat.campaignsAsCategories.length > 0) {     
                    state.categoriesAndSubCatList = listOfCategoriesAndSubCat.campaignsAsCategories.map((customCat: any) => {
                        index++; 
                        
                        return {
                            image: customCat.bannerInfo.bannerImgURL,
                            name: customCat.campaignInfo.name,
                            id: customCat.itemsCollectionType && customCat.itemsCollectionType != 0 && customCat.itemsCollectionType != 1
                                ? index
                                : customCat.itemsCollectionId || 0 ,
                            sortingPrio: customCat.campaignInfoorderListing,
                            isCustomCategory: true,
                            subcatList: [],
                            itemsCollectionType: customCat.itemsCollectionType,
                            isAlcohol: false,
                            isTobacco: false,
                            campaignId: customCat.id,
                        } as Category
                    })                
                }
            }

            // add list of categories
            listOfCategoriesAndSubCat.categories.forEach((category: any, index: number) => {
                state.categoriesAndSubCatList.push(Category.fetchCategoryObjectFromJson(category, index))
            });               

        },

        FETCH_LIST_OF_DEPARTMENTS(state, listOfDepartments: any) {
            state.departmentsList = listOfDepartments.map((dept: any) => Department.fetchDepartmentObjectFromJson(dept))
        },

        FETCH_LIST_OF_ITEMS(state, object: any) {
            state.listOfItems = [];
              
            if(typeof object != "undefined" 
                && object != null 
                && typeof object.listOfItems != "undefined" 
                && typeof object.listOfItems.items != "undefined" 
                && object.listOfItems.items.length > 0) 
            {
                // convert items server response to Product object
                state.listOfItems = object.listOfItems.items.map((item : any) => {
                    return ProductMappingBySubcategoryAndType.fetchItemMappingObjectFromJson(item);
                });
            }
        },

        FETCH_LIST_OF_SEARCHED_ITEMS(state, object: any) {
            if(typeof object.listOfItems != 'undefined')
                state.listOfSearchedItems = object.listOfItems.items.map((item: any) => Product.fetchItemObjectFromJson(item))
            
            else state.listOfSearchedItems = [];
        },

        SET_LAST_SUBCAT_SELECTED(state, subcat: Subcategory) { 
            state.lastSelectedSubcategory = subcat;
        },

        SET_LAST_CAT_SELECTED(state, cat: Category) { 
            state.lastSelectedCategory = cat;
        },

        async FETCH_SIMILAR_ITEMS_BY_TYPE(state, similarItems: any[]) {
            state.similarItemsByType = await similarItems.map((item: any) => {
                return Product.fetchItemObjectFromJson(item);
            });
        },

        FETCH_SIMILAR_ITEMS_BY_BRAND(state, similarItems: any[]) {
            state.similarItemsByBrand = similarItems.map((item: any) => {
                return Product.fetchItemObjectFromJson(item);
            });
        },

        FETCH_SIMILAR_ITEMS_BY_BOUGTH_WITH(state, similarItems: any[]) {
            state.similarItemsByBougthWith = similarItems.map((item: any) => {
                return Product.fetchItemObjectFromJson(item);
            });
        },

        SET_CURRENT_SELECTED_ITEM(state, currentSelectedItem) {
            state.currentSelectedItem = currentSelectedItem;
        },

        UPDATE_ITEM_DETAILS_DIALOG(state, itemDetailsDialogValue) {
            state.itemDetailsDialogValue = itemDetailsDialogValue;
        },

        FETCH_STORE_HOME_PAGE(state, storeHomePage: any) {
            // store home page products -> featured, recently added, promoted
            if(typeof storeHomePage.featuredItems != "undefined" && storeHomePage.featuredItems != null)
                state.storeHomePage.products.featuredItems = storeHomePage.featuredItems.map((item: any) => Product.fetchItemObjectFromJson(item));
            
            if(typeof storeHomePage.promoItems != "undefined" && storeHomePage.promoItems != null)
                state.storeHomePage.products.promoItems = storeHomePage.promoItems.map((item: any) => Product.fetchItemObjectFromJson(item));

            if(typeof storeHomePage.recentlyAddedItems != "undefined" && storeHomePage.recentlyAddedItems != null)
                state.storeHomePage.products.recentlyAddedItems = storeHomePage.recentlyAddedItems.map((item: any) => Product.fetchItemObjectFromJson(item));

            if(typeof storeHomePage.bundlesList != "undefined" && storeHomePage.bundlesList != null)
                state.storeHomePage.products.bundlesList = storeHomePage.bundlesList.map((item: any) => Product.fetchItemObjectFromJson(item));
            
            // custom collections 
            if(typeof storeHomePage.customCollections != "undefined" && storeHomePage.customCollections != null) {
                state.storeHomePage.customCollections = [] as any;
                storeHomePage.customCollections.forEach((collection: any) => {
                    state.storeHomePage.customCollections.push({
                        collectionOfItems: collection.collectionOfItems.map((item: any) => Product.fetchItemObjectFromJson(item)),
                        bannerInfo: collection.bannerInfo,
                        campaignInfo: collection.campaignInfo,
                        itemsCollectionId: collection.itemsCollectionId,
                        itemsCollectionType: collection.itemsCollectionType
                    });

                });
            }

            // banners
            if(typeof storeHomePage.customBanners != "undefined" && storeHomePage.customBanners != null)
                state.storeHomePage.customBanners = storeHomePage.customBanners;
            
            // brands
            if(typeof storeHomePage.brandsList != "undefined" && storeHomePage.brandsList != null)
                state.storeHomePage.brandsList = storeHomePage.brandsList;
            
            // catalogs
            if(typeof storeHomePage.catalogsList != "undefined" && storeHomePage.catalogsList != null)
                state.storeHomePage.catalogsList = storeHomePage.catalogsList 
                    ? storeHomePage.catalogsList.filter((x:any) => typeof x.images != "undefined" && x.images.length > 0)
                    : [];
        },
    },

    actions: {
        /** get list of categories */
        getDepartments({commit}, params) {
            return request({
                commit: commit,
                method: "post",
                url: Apis.departmentsSubURL,
                data: params,
                loaderKey: "",
                successCallback: (listOfDepartments: any) => {
                    commit("FETCH_LIST_OF_DEPARTMENTS", listOfDepartments);
                }
            })
        },


        /** get list of categories */
        getCategories({commit, rootState}, params) {
            return request({
                commit: commit,
                method: "post",
                url: Apis.categoriesSubURL,
                data: params,
                loaderKey: "listOfCategoriesLoading",
                successCallback: (listOfCategoriesAndSubCat: any) => {
                    commit("FETCH_LIST_OF_CATS_AND_SUBCATS", {
                        listOfCategoriesAndSubCat: listOfCategoriesAndSubCat,
                        storeCategorization: ((rootState as any)["store"] as StoreState).selectedStoreDetails.categorizationAndStructureLayer
                    });
                }
            })
        },

        /** get items */
        getItems({commit}, params) {                  
            return request({
                commit: commit,
                method: "post",
                url: Apis.itemsSubURL,
                data: params,
                loaderKey: "listOfItemsLoading",
                successCallback: (listOfItems: any) => {
                   commit("FETCH_LIST_OF_ITEMS", {listOfItems: listOfItems} );                    
                }
            })
        },

        searchItems({commit}, params) {
            return request({
                commit: commit,
                method: "post",
                url: Apis.itemsSearchURL,
                data: params,
                loaderKey: "searchItemsLoading",
                successCallback: (listOfItems: any) => {
                    commit("FETCH_LIST_OF_SEARCHED_ITEMS", {listOfItems: listOfItems} );                                     
                }
            })
            
        },

        /** get list of similar items by type */
        getSimilarItemsByType({ commit }, params) {            
            return request({
                method: "POST",
                url: Apis.itemsSimilarURL,
                data: {id: params.id, flavor: EItemFlavor.type},
                loaderKey: "similarItemsLoading",
                commit: commit,
                successCallback: (similarItems: any) => {
                    commit("FETCH_SIMILAR_ITEMS_BY_TYPE", similarItems);              
                },
            })
        },

        /** Similar items by Brand */
        getSimilarItemsByBrand({ commit }, params) {
            return request({
                method: "POST",
                url: Apis.itemsSimilarURL,
                data: {id: params.id, flavor: EItemFlavor.brand},
                commit: commit,
                loaderKey: "similarItemsLoading",
                successCallback: (similarItems: any) => {
                    commit("FETCH_SIMILAR_ITEMS_BY_BRAND", similarItems);
                },
            })
        },

        /** Similar items by boughtWith */
        getSimilarItemsByBoughtWith({ commit }, params) {
            return request({
                method: "POST",
                url: Apis.itemsSimilarURL,
                data: {id: params.id, flavor: EItemFlavor.boughtWith},
                commit: commit,
                loaderKey: "similarItemsLoading",
                successCallback: (similarItems: any) => {
                    commit("FETCH_SIMILAR_ITEMS_BY_BOUGTH_WITH", similarItems);
                },
            })
        },

        getStoreHomePage({commit}, params) {
            return request({
                method: "POST",
                url: Apis.storeHomePageSubUrl,
                data: params,                
                commit: commit,
                loaderKey: "storeHomePageLoading",
                successCallback: (storeHomePage: any) => {
                    commit("FETCH_STORE_HOME_PAGE", storeHomePage);
                }
            })
        }
    }
}