export enum ETypeOfRequest {
    BySubCat = 1,
    ByBrand = 2,
    ByCategory = 3,
    ByNewlyAdded = 4,
    ByPromo = 5,
    ByTopSelling = 6,
    ByCountry = 3,
    ByTheme = 7,
    ByBundles = 8,
    ByOOS = 9,           
    ByProbOOS = 10,      
    ByMissingItemInv = 11,
    ByFeaturedItems = 12,
    ByCustomCollectionOfItems = 13,
    ByRecentlyOrderedItems = 14
}