
// External libraries
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
    computed: {
        // Map the selectedPage from Vuex
        ...mapState('footerModule', ['selectedPage']),
    },
    watch: {
        // Watch for changes in selectedPage to dynamically update CSS
        selectedPage: {
            handler(newPage) {
            if (newPage && newPage.cssValue) {
                this.injectCss(newPage.cssValue)
            }
            },
            immediate: true,
        },
    },
    methods: {
        // Dynamically inject CSS into the document's head (and scoping it)
        injectCss(cssCode: string) {
            const prefix = '#scoped-container'
            
            // Prefix each CSS selector to scope it
            const scopedCss = cssCode.replace(/(^|\s)([^\s{}]+)(?=\s*{)/g, `$1${prefix} $2`)
            
            // Remove previously injected styles
            const existingStyleTag = document.getElementById('dynamic-css')
            if (existingStyleTag) {
                existingStyleTag.remove()
            }
            
            // Create and append a new style tag
            const styleTag = document.createElement('style')
            styleTag.id = 'dynamic-css'
            styleTag.type = 'text/css'
            styleTag.appendChild(document.createTextNode(scopedCss))
            document.head.appendChild(styleTag)
        }
    },
    beforeDestroy() {
        // Remove previously injected styles
        const existingStyleTag = document.getElementById('dynamic-css')
        if (existingStyleTag) {
            existingStyleTag.remove()
        }
    }
})
