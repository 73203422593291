<template>
  <v-card
    :to="{
      name: 'productDetailsPage',
      params: {
        productId: item.itemId, 
        productName: item.name
      }
    }"       
    :ripple="false"  
    class="item-card"
    flat
    :outlined="partnerSettings.isWsaItemCardOutlined"
    @click.exact="goToProductDetails"
    @mouseleave="isActionsBtnAreShown = false"
    @mouseenter="isActionsBtnAreShown = true"
    :width="`${elementWidth}px`"
  >

  <!-- Faded layer for oos items -->
    <div class="oos-layer" v-if="item.isOutOfStock == true" />
    
    <v-card-actions class="bundle-quantity " v-if="$route.name == 'productDetailsPage'  && (typeof(item.itemQuantityForBundle) != 'undefined' && item.itemQuantityForBundle != null && item.itemQuantityForBundle != 0)">      
      <div style="border-radius:18px; height:26px; font-size: 12px" :style="`background-color:${partnerSettings.actionButtonColor}; color: #FFF`" class="pa-3 my-2 mx-3 d-flex  align-center" >          
        x {{  item.itemQuantityForBundle }} 
      </div>
    </v-card-actions>
    
    <!-- card actions -->
    <v-card-actions class="item-card-actions" v-else>
      <CartActions :item="item" v-if="!loading && item.isOutOfStock == false"/>
    </v-card-actions>

    <!-- image-->
    <div class="item-card__image_container" :class="loading ? 'wave' : ''"  >
      <v-img contain 
          v-if="!loading && !imageFailedToLoad"
          :src="getImgUrl(item)"                  
          class="item-card__image" 
          :alt="item.name + '_image'"
          @error="imageFailedToLoad = true"
        />    
        
        <!-- image failed to load -->
        <span v-else-if="!loading && imageFailedToLoad" >                    
          <v-img contain                   
              src="https://suppystorage.blob.core.windows.net/valeur/wsa/icons/nopic.svg"
              class="item-card__image" 
              :alt="item.name + '_image'" 
              @error="imageFailedToLoad = true"
            />            
        </span>

        <!-- Item tag -->
        <div class="item-card-flag-container ">
          <ItemType :item="item" class="mx-0" :class="partnerSettings.isItemTagOnWSARotated == true ? 'rotated' : ''" fontSize="small"  />
        </div>
    </div>

      
    <!-- loader -->
    <v-card-text class="px-5 d-flex flex-column justify-end" v-if="loading" style="height:144px">        
      <p class="wave mb-3" style="height:10px; width: 30%; border-radius: 10px"></p>    
      <p class="wave mb-1" style="height:10px; border-radius: 10px"></p>   
      <p class="wave mb-1" style="height:10px; width: 85%; border-radius: 10px"></p>
      <p class="wave mb-1" style="height:10px; width: 70%; border-radius: 10px"></p>
    </v-card-text>

    <!-- details -->
    <v-card-text class="item-details-container" v-else>   

        <div>
          <div class="item-card-price-container">

            <!-- Pricce -->
            <MoneyFormat :typeOfAmount="item.isPromoted ? 2 : 1"  :price="item.adjPrice" /> 
            
            <!-- Promoted Price -->
            <template v-if="item.isPromoted && item.oldUnitPrice && item.oldUnitPrice > 0">
              &nbsp;
              <MoneyFormat :typeOfAmount="3"  :price="item.oldUnitPrice"/>
            </template>

          </div>

          <p v-if="!item.isPromoted && item.adjRRPValue > 0 && item.adjPrice < item.adjRRPValue" class="ma-0 pa-0 d-flex align center" style="font-size: 10px; color: grey !important;">
            RRP &nbsp;
            <MoneyFormat style="color: grey !important; font-weight: normal;"  :typeOfAmount="3"  :price="item.adjRRPValue"/>
          </p>
        </div>

        <item-unit-measure :item="item" v-if="isMobileViewDisplayed == false"/>
        
    
        <template v-if="!loading">
          <p class="item-name">
            {{ item.name }}
          </p>
        </template>      

        <item-unit-measure :item="item" v-if="isMobileViewDisplayed == true"/>
    </v-card-text>
  </v-card>

</template>

<script lang="js">
import MoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import CartActions from "@/components/Cart/CartActions/CartActions.vue";
import ItemType from "@/components/Items/ItemType.vue";
import {mapState, mapActions, mapMutations } from "vuex";
import ItemUnitMeasure from './ItemComponents/ItemUnitMeasure.vue';

export default {
  name: "item-card",
  components: { 
    MoneyFormat, 
    CartActions,  
    ItemType,
    "item-unit-measure": ItemUnitMeasure
  },

  props: {
    item: {
      required: false,
    }, 

    openItemDialog: {
      required: false,
      default: true,      
    },

    index: {
      required: false,
    },

    loading: {
      required: false,
      default: false,
    },

    elementWidth: {
      required: false,
      default: false,
    }
  },

  data: () => ({
    isActionsBtnAreShown: false,
    imageFailedToLoad: false
  }),

  computed:{
    ...mapState("ui",["isMobileViewDisplayed"]),
    ...mapState("partner",["partnerSettings", "appDataContent"]),
    ...mapState("store",["selectedStoreDetails"]),

    isBadgeDisplayed() {      
      return (this.item && (this.item.isOutOfStock || this.item.isFeatured || this.item.isPromoted)) || this.isWeight
    }

  },


  methods: {
    ...mapActions("product", ["getSimilarItemsByType", "getSimilarItemsByBrand", "getSimilarItemsByBoughtWith"]),
    ...mapMutations("product", ["SET_CURRENT_SELECTED_ITEM"]),

    closeDialog() {
      this.dialog = false;
    },


    async goToProductDetails() {      
      if (this.loading == true)
        return;
      
      else {
        this.SET_CURRENT_SELECTED_ITEM(this.item);        
      }
    },


    // items / categories image url
    getImgUrl(item) {      
      return `${this.partnerSettings.blobUrl}items/${item.image}`;            
    },
    
  }

};
</script>


<style lang="scss" scoped>
@import '@/styles/common.scss';

.item-card {
  display: flex;
  flex-direction: column;
  border-radius: $common-border-radius;
  cursor: pointer;
  border: thin solid rgba(0, 0, 0, 0.06);
  position: relative;
  padding-top: 8px;

  .item-card-actions {
    width: 100%;
    z-index: 1;
    padding-left: 12px;
    padding-right: 12px;
    height: 56px;
  }

  .bundle-quantity {
    height: 56px;
    width: 100%;
    z-index: 1;
  }

  .item-card__image_container {
    position: relative;
    margin-inline: auto;
    margin-top: 10px;
    border-radius: 22px;
    width: 90%;
    min-height: 150px;
    min-width: 145px;
    height: auto;
    z-index: 1;
    margin-right: auto;
    margin-left: auto;
  }

  .item-card__image {
    transition: 0.40s;
    width: 90%;
    min-width: 145px;
    margin-inline: auto;
  }

  .item-card-flag-container {
    margin-bottom: 8px;
    position:absolute; 
    bottom:2px; 
    left: 5px; 
  }

  .item-card-flag-container .rotated {
    transform: rotate(-3deg);
  }

  &:hover .item-card__image {
    transform: scale(1.08);
  }

  .item-details-container {
    padding-inline: 12px;
    padding-block: 8px;    
  }

  .item-card-price-container {
    display: flex;
    z-index: 2;
  }

  .item-name {
    margin-bottom: 0;
    color: black;
    font-size: 13px;    
    line-height: 22px;
    max-height: 44px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.mobile-view {
  .item-card {
    height: 300px;
  }

  .item-card__image_container {
    // max-height: 150px;
    min-height: 115px!important;
    min-width: 135px !important;
    height: 115px;

    .item-card__image {
      max-height: 100%;
      max-width: 100%;
      min-width: 135px !important;
    }
  }


  .item-name {
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
    max-height: 51px;
  }
}

.oos-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.6;
  z-index: 3;
}


</style>